@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global styles go here */
body,
html,
#__next {
  @apply scroll-smooth lg:overscroll-none;
  color: theme("colors.gray.dark");
}

main {
  position: relative;
}

#__next > footer {
  top: 100dvh;
  position: sticky;
}

/* Custom Scrollbars */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: theme("colors.green.pine") theme("colors.gray.light");
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

.modal::-webkit-scrollbar,
.modal ::-webkit-scrollbar,
.location-selector__states::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: theme("colors.green.pine");
  border-radius: 16px;
  border: 3px solid transparent;
}
/* End Custom Scrollbars */

ul {
  list-style: disc;
  padding-left: 20px;
}

li::marker {
  font-size: 12px;
}

.content li p {
  @apply py-1;
}

/* End Globals */

/* Text Styles */
h1,
h2,
h3,
h4,
h5,
h6,
p,
* {
  font-family: theme("fontFamily.primary");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

p {
  font-weight: 500;
}

h1 {
  @apply text-xl1 lg:text-xl3 leading-[46px] lg:leading-[54px];
}

h2 {
  @apply text-l3 lg:text-xl1 leading-[44px] lg:leading-[46px];
}

h3 {
  @apply text-l1 lg:text-l2;
}

h4 {
  @apply text-m3 lg:text-l1;
}

h5 {
  @apply text-m2 lg:text-m3;
}

h6 {
  @apply text-m1;
}

p {
  @apply text-s2 lg:text-s3;
}

/* End Text Styles */

/* Main Menu */

/* mobile menu button */
.mobile-menu-btn rect {
  stroke-width: 5;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: all 0.3s ease-in-out;
}

.mobile-menu-btn.open .top-line {
  transform: rotate(45deg);
  transform-origin: center top;
  x: 25px;
  y: 14px;
}

.mobile-menu-btn.open .middle-line {
  x: 0;
  opacity: 0;
}

.mobile-menu-btn.open .bottom-line {
  transform: rotate(-45deg);
  transform-origin: center top;
  x: -6px;
  y: 12.5px;
}

/* End mobile menu button */

/* prevent CTA btn from wrapping right at lg breakpoint */
header nav ~ a {
  white-space: nowrap;
}

.menu ul li,
.menu ul li a {
  cursor: pointer;
  color: theme(colors.gray.dark);
  text-decoration: none;
  white-space: nowrap;
  @apply transition-all relative;
}

@screen xl {
  .menu ul li,
  .menu ul li a {
    color: theme("colors.gray.dark");
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    @apply transition-all relative;
  }

  .menu ul li.top-level .submenu li {
    @apply p-0;
  }
  .menu ul.submenu li a {
    @apply text-gray-dark;
  }

  .menu > ul li:hover,
  .menu > ul li a:hover,
  .menu > ul li:focus-within,
  .menu > ul li a:focus-within {
    @apply underline;
    @apply transition-all;
  }

  .menu ul li a,
  .menu ul li div.nonlinked {
    display: block;
    align-items: center;
    height: 100%;
    width: 100%;
    @apply py-3;
  }

  /* top-level items */
  .menu ul li.top-level > a,
  .menu ul li.top-level > div {
    @apply pt-[10px] pl-3 pb-3 pr-0 relative cursor-pointer;
  }

  /* submenus */
  .menu > ul li ul {
    position: absolute;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    min-width: 160px;
    color: theme("colors.white");
    left: 0;
    @apply transition-all;
  }

  /* submenu items */
  .menu > ul li ul li {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: initial;
    font-weight: 300;
  }

  .menu > ul li ul li ul {
    top: 0;
    left: 100%;
  }

  .menu ul li.top-level {
    position: relative;
  }

  .menu ul .top-level:has(.current-page):after {
    content: "";
    position: absolute;
    width: calc(100% - 1.5rem);
    bottom: 0rem;
    left: 0.75rem;
    z-index: -1;
    height: 4px;

    @apply transition-all;
  }

  .menu ul li:hover > ul.submenu,
  .menu ul li:focus-within > ul.submenu {
    opacity: 1;
    max-height: 100vh;
    overflow: visible;
  }
}

.header-link-arrow {
  @apply after:transition-all after:duration-500 after:inline-block after:absolute after:content-['›'] after:translate-x-1 hover:underline hover:after:translate-x-1.5;
}

.header-link-arrow-down {
  @apply after:inline-block after:absolute after:content-['›'] after:translate-x-2 after:rotate-90;
}

/* End Main Menu */

/* Team Member Carousel */
.carousel-arrow .pawprint-toe {
  opacity: 0;
  transform: scaleX(0.75);
  @apply transition-all duration-300;
}

.carousel-arrow:hover .pawprint-toe {
  opacity: 1;
  transform: scaleX(1);
}

.carousel-arrow .pawprint-chevron {
  @apply transition-all;
}

.carousel-arrow:hover .pawprint-chevron {
  @apply translate-x-[2px] duration-700 ease-in-out;
}

.carousel-arrow .pawprint-toe:nth-child(1) {
  transition-delay: 100ms;
}
.carousel-arrow .pawprint-toe:nth-child(2) {
  transition-delay: 200ms;
}
.carousel-arrow .pawprint-toe:nth-child(3) {
  transition-delay: 300ms;
}
.carousel-arrow .pawprint-toe:nth-child(4) {
  transition-delay: 400ms;
}
/* End Team Member Carousel */

/* Content with Checkered Border */
.checkerboard-pattern {
  background-image: linear-gradient(135deg, transparent 75%, #e8e8e8 75%),
    linear-gradient(135deg, #e8e8e8 25%, transparent 25%), linear-gradient(135deg, #e8e8e8 25%, transparent 25%),
    linear-gradient(135deg, transparent 75%, #e8e8e8 75%);
  background-size: 160px 160px;
  background-position: 0 0, 0 0, -80px -80px, 80px 80px;
}
/* End Content with Checkered Border */

/* Interactive Map */
.modal .find-your-location-map-container {
  max-height: 85dvh;
}

.modal .location-selector {
  @apply md:flex md:flex-col md:overflow-hidden;
}

.modal .location-selector--states {
  @apply md:overflow-y-auto;
}

.modal .request-new-location {
  display: none;
}

.map-pin {
  cursor: pointer;
}

.map-pin path {
  @apply transition-all;
}

.map-pin path {
  transform: scaleY(0);
  transform-origin: bottom;
}

.maplibregl-map.loaded .map-pin path {
  transition-delay: 1s;
  transform: scaleY(1);
}

.request-location-chevron {
  position: relative;
}

.request-location-chevron::after {
  content: "›";
  transform: translateX(5px);
  position: absolute;
  @apply transition-all;
}

.maplibregl-popup {
  @apply animate-fade-in;
}

.maplibregl-popup-content {
  @apply border-2 font-sans leading-normal border-green-apple shadow-lg p-0 max-w-xs !important;
}

.maplibregl-popup-content > div {
  @apply px-6 pt-5 pb-3;
}

.maplibregl-popup-content button {
  @apply text-m1;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.maplibregl-popup-anchor-bottom .maplibregl-popup-tip {
  border-top-color: theme("colors.green.apple") !important;
}

.location-selector-tooltip:before {
  content: "";
  width: 15px;
  height: 15px;
  border: 10px solid transparent;
  border-top: none;
  border-bottom-color: #76bf43 !important;
  display: block;
  top: -1rem;
  left: 5%;
  position: absolute;
}

/* End Interactive Map */

/* Begin Loading Indicator */

/* 5 paws */
/* 2 overlapping at once, with one trailing */
.loading-paws .paw {
  opacity: 0;
  fill: theme("colors.green.sea");
  animation-name: step;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 5s;
}

.loading-paws .paw-1 {
  animation-delay: 1s;
}
.loading-paws .paw-2 {
  animation-delay: 2s;
}
.loading-paws .paw-3 {
  animation-delay: 3s;
}
.loading-paws .paw-4 {
  animation-delay: 4s;
}
.loading-paws .paw-5 {
  animation-delay: 5s;
}

@keyframes step {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
}

/* End Loading Indicator */

/* Dynamic Video */
.play-btn:hover .triangle {
  fill: white;
}
/* End Dynamic Video */

/* Membership Form */
.membership-form label > input[type="checkbox"] {
  display: none;
}

.membership-form label > input[type="checkbox"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: center;
  transform: translate(0, 3px);
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border-style: solid;
  border-width: 0.1rem;
  margin-right: 0.5rem;
  border-color: #76bf43;
}

.membership-form label > input[type="checkbox"]:checked + *::before {
  content: url("./public/assets/green-check.svg");
  color: white;
  text-align: center;
  background: #76bf43;
  border-color: #76bf43;
}

.pricing-section-content > a {
  display: block;
}
/* End Membership Form */

/* Ensure that any element which contains a gray pawprint background will not show the background if it is nested with a Pawprint Background Section  */
.bg-grayPawprint .bg-grayPawprint {
  background: none;
}

.bg-grayPawprint {
  /* This fixes this issue: https://github.com/2060-Digital/dogtown/issues/83 */
  background-color: theme("colors.gray.dark");

  /* Boom, parallax! */
  @apply bg-fixed;
}

/* Contact Map Section */
.bg-lounging-dog {
  position: relative;
  @apply pb-40;
}

.bg-lounging-dog::after {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 110%;
  height: 100%;

  background: center / contain no-repeat url("/assets/pets/lounging-dog.png");
}

@screen xl {
  .bg-lounging-dog::after {
    width: 115%;
    right: unset;
    left: 0;
    top: 10px;
  }
}
/* End Contact Map Section */

/* Intro with image */
.intro-with-image-content h1 + p {
  padding-top: 12px;
}
/* End Intro with image */
/* Sales message */
.sales-star-gray {
  background: linear-gradient(to bottom, transparent 0 63%, theme("colors.gray.dark") 63% 100%);
}
.sales-star-white {
  background: linear-gradient(to bottom, transparent 0 63%, white 63% 100%);
}
/* End Sales message */

.calendly-embed {
  min-width: 320px;
  width: 100%;
  min-height: 1100px;
}

@screen xl {
  .calendly-embed {
    min-height: 800px;
  }
}

/* Modal */
.modal:focus {
  outline: none;
}
/* End Modal */

/* Grooming Portfolio Section */
.portfolio-link {
  display: flex;
  gap: theme("spacing.1");
  align-items: center;
  width: max-content;
}
.portfolio-link::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.portfolio-link::before {
  background-image: url("public/assets/portfolio-icon.svg");
  width: 12px;
  height: 12px;
}
/* End Grooming Portfolio Section */
